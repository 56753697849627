import React from "react";
import styled from "styled-components";
import "./styles/App.css";

const IntroParagraph = styled.div`
  position: flex;

  font-size: 1.5em;
  width: 40%;
  line-height: 1.5em;
  text-align: center;
  color: white;

  @media (max-width: 400px) {
    font-size: 1em;
  }
`;

const Word = styled.div`
  justify-content: center;
  display: flex;
  padding: 10px;
`;

function Home() {
  return (
    <div className="one">
      <div className="top">
        <div stlye={{ display: "flex" }}>
          <h3 className="title">Hey ✨ I'm Diana Yue {":)"}</h3>
        </div>
      </div>
      <div className="bottom">
        <div className="Image2" />
        <IntroParagraph>
          <Word style={{ fontWeight: "bolder" }}>I care about...</Word>
          <Word>
            🌍 using technology to study, improve, and serve the ontology of
            human beings and experiences
          </Word>
          <Word>👩‍🎨 experimenting with ways of creative visual storytelling</Word>
        </IntroParagraph>
      </div>
    </div>
  );
}
export default Home;
